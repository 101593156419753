import { createContext } from 'react';

const UserContext = createContext({
    loggedIn: false,
    email: null,
    fullName: null,
    token: null
  });

// Export the context
export default UserContext;