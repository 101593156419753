import { BrowserRouter, Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import Login from  './components/home/Login';
import Home from './components/home/Home';
import MainUI from './components/chatbot/MainUI';
import SessionPage from './components/chatbot/Session';
import WebChatSession from './components/chatbot/WebChatSession';
import DatabaseChatSession from './components/chatbot/DatabaseChatSession';
import ConfluenceChatSession from './components/chatbot/ConfluenceChatSession';
// import Login from './login';
import './App.css';
import { useEffect, useState } from 'react';
import TokenContext from './components/contexts/TokenContext';
import UserContext from './components/contexts/UserContext';

function App() {
  const host = window.location.host;
  const protocol = window.location.protocol;
  const main_url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")
  const [token, setToken] = useState("")
  const [fullName, setFullName] = useState("")
  // Fetch the user email and token from local storage
  const user = JSON.parse(localStorage.getItem("user"))
  console.log('user', user)

  function StoreToken() {
    const location = useLocation();
    const navigate = useNavigate();
    console.log("storeToken")
  
    useEffect(() => {
      // Extract the access token from the URL
      const urlParams = new URLSearchParams(location.search);
      const token = urlParams.get('token');
      const user = {
        email: urlParams.get('email'),
        token: token
      }
  
      // Store the access token in local storage
      localStorage.setItem('user', JSON.stringify(user));
      // console.log('user', user)
      // Navigate to /home
      navigate('/');
      // if (loggedIn) {
      //   // Navigate to /home
      //   navigate('/');
      // }
    }, [location, navigate]);
  
    return null;
  }

  function PrivateRoute({ children, loggedIn }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const user = JSON.parse(localStorage.getItem("user"))
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const api_url = `${main_url}/api/v1/auth/verify-token`
          const response = await fetch(api_url, {
            method: "GET",
            headers: {
              'token': user.token
            }
          });

          const status = response.status;
          if(status == 402){
            console.log("license expired");
          }
          else if(status == 200){
            console.log("verify token success");
          }
          else{
            setError(true);
            setLoading(false);
            console.log("verify token error", status);
            return;
          }
  
          const data = await response.json();
          // console.log("r", data);
  
          setLoggedIn(true);
          setEmail(user.email || "");
  
          if ('success' === data.message) {
            TokenContext.token = user.token;
            console.log("logged in");
            setLoading(false);

            // const email = data.data.email;
            // const fullName = data.data.full_name;
            // setUserInfo({
            //   email: data.data.email,
            //   fullName: data.data.full_name,
            //   loggedIn: true
            // });
            setEmail(data.data.email);
            setFullName(data.data.full_name);
            setLoggedIn(true);
            setToken(user.token);
          }
        } catch (error) {
          console.error('Failed to fetch data', error);
          setError(true);
          setLoading(false);
        }
      };
  
      fetchData();
    }, []);
  
    if (loading) {
      return <div>Loading...</div>; // Replace with your loading component
    }
  
    if (error) {
      return <Navigate to="/login" />;
    }
  
    return children;
  }

  return (
    <UserContext.Provider value={{loggedIn: loggedIn, fullName: fullName, email: email , token: token}}>
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/" element={
                <PrivateRoute loggedIn={loggedIn}>
                <Home />
              </PrivateRoute>
          } />
          <Route path="/login" element={<Login email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
          <Route path="/storeToken" element={<StoreToken />} /> {/* Add this line */}
          <Route path="/datachat/session" element={
                <PrivateRoute loggedIn={loggedIn}>
                <MainUI />
              </PrivateRoute>
          } />
          <Route path="/datachat/document" element={
                <PrivateRoute loggedIn={loggedIn}>
                <SessionPage />
              </PrivateRoute>
          } />
          <Route path="/datachat/web" element={
                <PrivateRoute loggedIn={loggedIn}>
                <WebChatSession />
              </PrivateRoute>
          } />
          <Route path="/datachat/database" element={
                <PrivateRoute loggedIn={loggedIn}>
                <DatabaseChatSession />
              </PrivateRoute>
          } />
          <Route path="/datachat/confluence" element={
                <PrivateRoute loggedIn={loggedIn}>
                <ConfluenceChatSession />
              </PrivateRoute>
          } />
        </Routes>
      </BrowserRouter>
    </div>
    </UserContext.Provider>
  );
}

export default App;