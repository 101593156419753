import React, { useEffect, useState } from 'react';
import { ThunderboltOutlined, HeartOutlined, CalendarOutlined } from '@ant-design/icons';


const License = () => {
    const [remainingTokens, setRemainingTokens] = useState(10);
    const [remainingDays, setRemainingDays] = useState(10);
    const [packetName, setPacketName] = useState('TRIAL');
    const host = window.location.host;
    const protocol = window.location.protocol;
    const main_url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

    useEffect(() => {
        // Fetch license information from the backend API
        const fetchData = async () => {
            try {
              const api_url = `${main_url}/api/v1/chat-session/license`
              const user = JSON.parse(localStorage.getItem("user"))
              const response = await fetch(api_url, {
                method: "GET",
                headers: {
                  'token': user.token
                }
              });
    
              const status = response.status;
              if(status == 402){
                console.log("license expired");
              }
              else if(status == 200){
                const data = await response.json();
                console.log("get license info: success", data.data);
                setRemainingTokens(data.data.remaining_tokens);
                setRemainingDays(data.data.remaining_days);
                setPacketName(data.data.license_type);
              }
              else{
                console.log("verify token error", status);
                return;
              }
      
            } catch (error) {
              console.error('Failed to fetch data', error);
            }
          };
          fetchData();
    }, []);

    return (
        <div style={{ color: 'black' , paddingRight: '50px', fontSize: '13px'}}>
            <div>
                <ThunderboltOutlined style={{ color: 'blue' }} />
                <span style={{ paddingRight: '10px' }}> {packetName}</span>                
                <HeartOutlined style={{ color: 'red' }} />
                <span style={{ paddingRight: '10px' }} > {remainingTokens}%</span>
                <CalendarOutlined style={{ color: 'blue' }} />
                <span style={{ paddingRight: '10px' }} > {remainingDays} days</span>
            </div>    
        </div>
    );
};

export default License;