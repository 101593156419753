import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./ConfluenceChatSession.css";

import { Select, Space } from 'antd';
import { AndroidOutlined, AppleOutlined, IeOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Form, Input } from 'antd';
import { Upload, Button } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

import { message } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType } from "../contexts/Constant";
import { AntDesignOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import UserContext from '../contexts/UserContext';

const { Dragger } = Upload;


function ConfluenceChatSession() {

  const { loggedIn, email, fullName, api_token } = useContext(UserContext);


  useEffect(() => {
    console.log("ConfluenceChatSession");
  }, []);

  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  var latestFile = useRef();
  var latestSessionId = useRef();

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  async function verify_confluence_info(details) {
    try {
      const api_url = `${url}/api/v1/chat-session/verify-confluence`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify(details)
      });

      if (response.status === 200) {
        console.log('verify confluence info: success');
        return true;
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    alert("Invalid Confluence Infomaion, Please check again.");
    return false;
  }

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type,
          source_id: source_id,
          details: details
        })
      });

      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if (status == 402) {
        alert("License expired. Please contact support.");
      }
      else if (status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if (status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else {
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function start_new_session(source_id, details) {
    try {
      const result = await verify_confluence_info(details);
      if (!result) {
        return;
      }

      const session_id = await create_chat_session(DataChatServiceType.CONFLUENCE_CHAT, source_id, details);
      if (!session_id) {
        return;
      }

      latestSessionId = session_id;

      let data = {
        sessionId: latestSessionId,
      };
      socket_tx.current.emit("register", JSON.stringify(data));

      data = {
        sessionId: latestSessionId,
        file_name: "",
        fileType: "",
        datachat_type: DataChatServiceType.CONFLUENCE_CHAT,
        details: details
      };
      socket_tx.current.emit("start_new_session", JSON.stringify(data));

      setIsLoading(true);
    } catch (error) {
      console.log('Could not start new session');
    }
    return false;
  }

  async function handle_inputform(values) {
    const details = {
      confluence_base_url: values.url,
      username: values.username,
      api_token: values.tokenkey,
    }
    const url = `${details.url}/${details.tokenkey}`;
    const source_id = USER_ID + '_' + url.hashUrlToUuid();
    await start_new_session(source_id, details);
  }

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.CONFLUENCE_CHAT}&docs=a&id=${latestSessionId}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  return (
    <div>
      <Header title="header" />
      <div className="cf-body">
        {isLoading && (
          <div className="ss-modal">
            <span class="ss-loader"></span>Analyzing
          </div>
        )}
        <h1 className="cf-instroduce">
          CONFLUENCE WIKI CHAT
        </h1>
        <div className="cf-main-working" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="cf-working" style={{ display: "flex", justifyContent: "center" }}>

            <Tabs
              defaultActiveKey="1"
              centered={true}
              items={[AndroidOutlined].map((Icon, i) => {
                const id = String(i + 1);
                return {
                  key: id,
                  label: i === 0 ? "Confluence" : `Database`,
                  children: (
                    <Form labelAlign="left" 
                    initialValues={{ username: email }}
                      onFinish={async (values) => handle_inputform(values)}>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label="URL"
                            name="url"
                            rules={[{ required: true, message: 'Please input your url!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username or email!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item
                            label="Token Key"
                            name="tokenkey"
                            rules={[{ required: true, message: 'Please input your token!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>
                      </Row>
                      <ConfigProvider
                        button={{
                          className: "cf-connect",
                        }}
                      >
                        <Space style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button type="primary" size="small" icon={<AntDesignOutlined />} htmlType="submit">
                            Connect
                          </Button>
                        </Space>
                      </ConfigProvider>
                    </Form>
                  ),
                  icon: i === 0 ? <img className="icon-image" src='https://play-lh.googleusercontent.com/-aex9dK8-hchgNFf5lsMCy0_9sl6kK_JIS4nh-6p3_NG9w2BwASOTRsNg-tgnONg8Q' alt="icon2" width="20" height="20" /> : <IeOutlined />,
                };
              })}
              size="large"
            />

          </div>
        </div>



      </div>

    </div>
  );
}

export default ConfluenceChatSession;