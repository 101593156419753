import React from "react";
import "./header.css";
import { UserOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Avatar, Space } from "antd";
import { Link, useNavigate} from "react-router-dom";
import { useContext } from 'react';
import UserContext from '../contexts/UserContext';

import License from "../license/License";

function Header() {
  const { loggedIn, email, fullName, token } = useContext(UserContext);

  const logout = async () => {
    try{
      console.log("logout")
      // const api = 'http://localhost:5000/api/v1/auth/logout'
      const api = '/api/v1/auth/logout'
      const response = await fetch(api, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'token': token
        },
      });
      console.log(response)
  
      if(response.status == 200){
        console.log("logout success")
        localStorage.removeItem("user")
        window.location.href = '/';
      }
    }
    catch(e){
      console.log(e)
    }

  };
  const menu = (
    <Menu>
      <Menu.Item key="0">
      <button onClick={logout}>Logout</button>
      </Menu.Item>
    </Menu>
  );

  console.log("full_name", fullName)
  console.log("loggedIn", loggedIn)
  return (
    <div className="Header">
      <Link to="/">
        <img
          src="https://verysell.ai/wp-content/uploads/2023/08/AI-Lab-Logo-Final.png"
          alt="Logo"
        />
      </Link>
      <Space direction="vertical" size={16}>
        {/* <Space wrap size={16}> */}
          {/* <Avatar size={64} icon={<UserOutlined />} /> */}
          {/* <Avatar */}
            {/* size="large" */}
            {/* icon={<UserOutlined />} */}
            {/* style={{ marginRight: "20px" }} */}
          {/* /> */}
          {/* Replace "Username" with the actual username */}
          {/* <Avatar icon={<UserOutlined />} /> */}
        {/* </Space> */}
        <Space wrap size={16}>
      {loggedIn ? (
        <>
      <License title="license" />
        <span style={{ color: 'black'}}>{fullName}</span> {/* Replace with the actual username field */}
        <Dropdown overlay={menu} trigger={['click']}>
        <Avatar
          size="small"
          icon={<UserOutlined />}
          style={{ marginRight: "20px" }}
        />
      </Dropdown>
        </>
      ) : (
        <Avatar
          size="small"
          icon={<UserOutlined />}
          style={{ marginRight: "20px" }}
        />
      )}
    </Space>        
      </Space>
    </div>
  );
}

export default Header;
