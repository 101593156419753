import React, { useRef, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./DatabaseChatSession.css";

import { Select, Space } from 'antd';
import { AndroidOutlined, AppleOutlined, IeOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { Form, Input } from 'antd';
import { Upload, Button } from 'antd';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

import { message } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType, DatabaseType } from "../contexts/Constant";
import { AntDesignOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';

const { Dragger } = Upload;


const handleChange = (value) => {
  console.log(`selected ${value}`);
};

function Block({ title, text, link }) {
  if (link === "/") {
    return (
      <li>
        <h3>{title}</h3>
        <div className="text">{text}</div>
      </li>
    );
  }
  return (
    <li>
      <h3>{title}</h3>
      <div className="text">{text}</div>
    </li>

  );
}

function DatabaseChatSession() {

  useEffect(() => {
    console.log("DatabaseChatSession");
  }, []);

  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  var latestFile = useRef();
  var latestSessionId = useRef();

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type,
          source_id: source_id,
          details: details
        })
      });

      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if (status == 402) {
        alert("License expired. Please contact support.");
      }
      else if (status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if (status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else {
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function start_new_session(source_id, details) {
    try {
      const session_id = await create_chat_session(DataChatServiceType.DATABASE_CHAT, source_id, details);
      if (!session_id) {
        return;
      }

      latestSessionId = session_id;

      let data = {
        sessionId: latestSessionId,
      };
      socket_tx.current.emit("register", JSON.stringify(data));

      data = {
        sessionId: latestSessionId,
        file_name: "",
        fileType: "",
        datachat_type: DataChatServiceType.DATABASE_CHAT,
        details: details
      };
      socket_tx.current.emit("start_new_session", JSON.stringify(data));

      setIsLoading(true);
    } catch (error) {
      console.log('Could not start new session');
    }
    return false;
  }

  async function handle_db_inputform(values) {
    const details = {
      input_type: values.dbType,
      path: "",
      username: values.username,
      password: values.password,
      host: values.host,
      port: values.port,
      db_name: values.dbName
    }
    const url = `${details.input_type}:${details.host}:${details.port}/${details.db_name}`;
    const source_id = USER_ID + '_' + url.hashUrlToUuid();
    await start_new_session(source_id, details);
  }

  const props = {
    name: "file",
    multiple: true,
    action: `${url}/upload`,
    headers: {
      'token': user.token,
    },
    onChange: async (info) => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // check result from api response
        console.log("API response", info.file.response);
        const document_id = info.file.response.documentId;
        if (info.file.response.result === 1) {
          message.success("File uploaded successfully.");
        }
        else if (info.file.response.result === 2) {
          message.info("The file already exists.")
          // var path_name = `/datachat?docs=${info.file.response.fileName}`;
          // navigate(path_name);
        }
        // navigate("/datachat");
        latestFile = info.file.response.fileName;
        const source_id = USER_ID + '_' + document_id;

        const details = {
          input_type: DatabaseType.SQL_LITE,
          path: info.file.response.fileName,
          username: "",
          password: "",
          host: "",
          port: ""
        }

        await start_new_session(source_id, details);

        // var path_name = `/datachat?docs=${latestFile}`;
        // navigate(path_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        const status_code = info.file.error.status;
        if (status_code === 401) {
          message.error("Unauthorized access. Please login again.");
          navigate("/login");
        }
        else if (status_code == 402) {
          alert("License expired. Please contact support.");
        }
        else if (status_code == 403) {
          alert("Reach the maximum token limit in the license. Please contact support.");
        }
        else if (status_code == 404) {
          alert("Invalid License. Please contact support.");
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.DATABASE_CHAT}&docs=self_RAG.pdf&id=${latestSessionId}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  const { getPrefixCls } = useContext(ConfigProvider.ConfigContext);

  return (
    <div>
      <Header title="header" />
      <div className="db-body">
        {isLoading && (
          <div className="ss-modal">
            <span class="ss-loader"></span>Analyzing
          </div>
        )}
        <h1 className="db-instroduce">
          DATABASE CHAT
        </h1>
        <div className="db-main-working" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className="db-working" style={{ display: "flex", justifyContent: "center" }}>

            <Tabs
              defaultActiveKey="1"
              centered={true}
              items={[AppleOutlined, AndroidOutlined].map((Icon, i) => {
                const id = String(i + 1);
                return {
                  key: id,
                  label: i === 0 ? "File" : `Database`,
                  children: i === 0 ? (
                    <Dragger
                      beforeUpload={(file, fileList) => {
                        if (fileList.length > 1) {
                          message.error('You can only upload one file!');
                          return false;
                        }
                        // Check file format
                        const isSqlite = file.name.endsWith('.sqlite');
                        if (!isSqlite) {
                          message.error('You can only upload SQLite files!');
                        }
                        return isSqlite;
                      }}
                      {...props}
                      style={{
                        width: "400px",
                        maxHeight: "200px",
                        textAlign: "center",
                        backgroundColor: "rgb(255 244 244)",
                        border: "2px dashed #666",
                        borderRadius: "30px",
                      }}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Click or drag a file to this area to upload
                      </p>
                      <p className="ant-upload-hint">Support: SQL Lite.</p>
                    </Dragger>
                  ) : (
                    <Form labelAlign="left"
                      onFinish={async (values) => handle_db_inputform(values)}>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Type"
                            name="dbType"
                            rules={[{ required: true, message: 'Please select your DB type!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Select onChange={handleChange}>
                              <Select.Option value={DatabaseType.MYSQL}>MySQL</Select.Option>
                              <Select.Option value={DatabaseType.POSTGRESQL}>PostgreSQL</Select.Option>
                              {/* Add more options as needed */}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="DB Name"
                            name="dbName"
                            rules={[{ required: true, message: 'Please input your database name!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Host"
                            name="host"
                            rules={[{ required: true, message: 'Please input your host!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Port"
                            name="port"
                            rules={[
                              { required: true, message: 'Please input your port!' },
                              { pattern: /^[0-9]+$/, message: 'Port must be a number!' }
                            ]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                          >
                            <Input.Password />
                          </Form.Item>
                        </Col>
                      </Row>
                      <ConfigProvider
                        button={{
                          className: "db-connect",
                        }}
                      >
                        <Space style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button type="primary" size="small" icon={<AntDesignOutlined />} htmlType="submit">
                            Connect
                          </Button>
                        </Space>
                      </ConfigProvider>
                    </Form>
                  ),
                  icon: i === 0 ? <UploadOutlined /> : <IeOutlined />,
                };
              })}
              size="large"
            />

          </div>
        </div>



      </div>

    </div>
  );
}

export default DatabaseChatSession;