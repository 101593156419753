export const DataChatServiceType = {
    DOCUMENT_CHAT: 'document',
    WEB_CHAT: 'web',
    DATABASE_CHAT: 'database',
    CONFLUENCE_CHAT: 'confluence',
    SHAREPOINT_FOLDER_CHAT: 'sharepoint',
    MAILBOX_CHAT: 'mailbox'
}

export const DatabaseType = {
    SQL_LITE: 'sql_lite',
    POSTGRESQL: 'postgre_sql',
    MYSQL: 'my_sql'
}