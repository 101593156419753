import React from 'react';
import { Link } from 'react-router-dom';
import { TinyColor } from '@ctrl/tinycolor';
import { Button, ConfigProvider, Space } from 'antd';
import "./Home.css";

import Header from "../header/header";


function Block({ title, text, link }) {
  if (link === "/") {
    return (
      <Link className="home-block" style={{ textDecoration: 'none' }}>
        <li>
          <h3>{title}</h3>
          <div className="text">{text}</div>
        </li>
      </Link>
    );
  }
  return (

    <Link className="home-block" to={link} style={{ textDecoration: 'none' }}>
      <li>
        <h3>{title}</h3>
        <div className="text">{text}</div>
      </li>
    </Link>

  );
}

const Home = () => (
  <div>
    <Header title="header" />

    <div className="mainHomeContainer">
      <div className={"homeTitleContainer"}> VERYCHAT PLATFORM</div>
      <ul className="benefits-list">
        <li className="block">
          <Block
            title="DOCUMENT CHAT"
            text="Chat with the document"
            link="/datachat/document"
          />
        </li>
        <li className="block">
          <Block
            title="WEB CHAT"
            text="Chat with the website"
            link="/datachat/web"
          />
        </li>
        <li className="block">
          <Block
            title="DATABASE CHAT"
            text="Chat with the database"
            link="/datachat/database"
          />
        </li>
        <li className="block">
          <Block
            title="CONFLUENCE WIKI CHAT"
            text="Chat with the confluence wiki"
            link="/datachat/confluence"
          />
        </li>
        <li className="block">
          <Block
            title="SHAREPOINT FOLDER CHAT"
            text="(comming soon)"
            link="/"
          />
        </li>
        <li className="block">
          <Block
            title="MAILBOX CHAT"
            text="(comming soon)"
            link="/"
          />
        </li>
      </ul>
    </div>

  </div>

);
export default Home;