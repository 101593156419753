import React from "react"
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./Login.css";

import { Button, Checkbox, Form, Input } from 'antd';
const onFinish = (values) => {
  console.log('Success:', values);
};
const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const Login = (props) => {
    const { loggedIn, email } = props
    const navigate = useNavigate();
    const host = window.location.host;
    const protocol = window.location.protocol;
    const main_url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;
    console.log("main_url", main_url)
    
    const signInWithMicrosoft = async () => {
        if (loggedIn) {
            localStorage.removeItem("user")
            props.setLoggedIn(false)
        } else {
            const response = await fetch(`${main_url}/api/v1/auth/external-login-url`);
            console.log(response)
            const data = await response.json();
            const url = data.data.url;
            // Redirect to the URL
            window.location.href = url;
        }
    }

    if (loggedIn) {
        console.log("navigate to /")
        navigate('/')
    }


    return <div>
        <Header title="header" />
    
    <div className="mainLoginContainer">
        <div className={"titleLoginContainer"}>
            <div>Welcome!</div>
        </div>
        {/* <div className={"ContentContainer"}>
            Data Chat Platform.
        </div> */}

        
        
        <div className={"buttonContainer"}>
        <Form
            name="basic"
            labelCol={{
            span: 8,
            }}
            wrapperCol={{
            span: 16,
            }}
            style={{
            maxWidth: 600,
            width: 380,
            }}
            initialValues={{
            remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
            label="Username"
            name="username"
            rules={[
                {
                required: true,
                message: 'Please input your username!',
                },
            ]}
            >
            <Input />
            </Form.Item>

            <Form.Item
            label="Password"
            name="password"
            rules={[
                {
                required: true,
                message: 'Please input your password!',
                },
            ]}
            >
            <Input.Password />
            </Form.Item>

            <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
            >
            <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
            >
            <Button type="primary" htmlType="submit" style={{ marginRight: '10px' }}>
                Sign in
            </Button>
            <Button type="primary" onClick={signInWithMicrosoft}>
                Sign in with Microsoft 
            </Button>
            
            </Form.Item>
        </Form>

            
        </div>


    </div>
    </div>
}

export default Login