import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../header/header";
import "./Session.css";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import { DataChatServiceType } from "../contexts/Constant";

const { Dragger } = Upload;

function SessionPage() {
  let userId = Cookies.get("userId");
  const user = JSON.parse(localStorage.getItem("user"))
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }
  var USER_ID = userId;

  var latestFile = useRef();
  var latestSessionId = useRef();

  const host = window.location.host;
  const protocol = window.location.protocol;
  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;

  const [isLoading, setIsLoading] = useState(false);

  // const history = useHistory();
  const navigate = useNavigate();
  const socket_tx = useRef();

  async function create_chat_session(chat_service_type, source_id, details) {
    try {
      const api_url = `${url}/api/v1/chat-session/create`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({
          chat_service_type: chat_service_type, 
          source_id: source_id, 
          details: details 
        })
      });
      
      const status = response.status;
      if (status === 200) {
        console.log('create chat session: success');
        const data = await response.json();
        const session_id = data.data.session_id;
        return session_id;
      }
      else if (status === 401) {
        message.error("Unauthorized access. Please login again.");
        navigate("/login");
      }
      else if(status == 402) {
        alert("License expired. Please contact support.");
      }
      else if(status == 403) {
        alert("Reach the maximum token limit in the license. Please contact support.");
      }
      else if(status == 404) {
        alert("Invalid License. Please contact support.");
      }
      else{
        alert(`create chat session: ${status}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  const props = {
    name: "file",
    multiple: true,
    action: `${url}/upload`,
    headers: {
      'token': user.token,
    },
    onChange: async (info) => {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // message.success(`${info.file.name} file uploaded successfully.`);
        // check result from api response
        console.log("API response", info.file.response);
        const document_id = info.file.response.documentId;
        if (info.file.response.result === 1) {
          message.success("File uploaded successfully.");
        }
        else if (info.file.response.result === 2) {
          message.info("The file already exists.")
          // var path_name = `/datachat?docs=${info.file.response.fileName}`;
          // navigate(path_name);
        }
        // navigate("/datachat");
        latestFile = info.file.response.fileName;
        const source_id = USER_ID + '_' + document_id;

        const session_id = await create_chat_session(DataChatServiceType.DOCUMENT_CHAT, source_id, {
          file_name: info.file.response.fileName,
          file_type: info.file.response.fileType
        });
        if (!session_id) {
          return;
        }

        latestSessionId = session_id;

        let data = {
          sessionId: latestSessionId,
        };
        socket_tx.current.emit("register", JSON.stringify(data));

        data = {
          sessionId: latestSessionId,
          file_name: info.file.response.fileName,
          fileType: info.file.response.fileType,
          datachat_type: DataChatServiceType.DOCUMENT_CHAT
        };
        socket_tx.current.emit("start_new_session", JSON.stringify(data));

        setIsLoading(true);

        // var path_name = `/datachat?docs=${latestFile}`;
        // navigate(path_name);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        const status_code = info.file.error.status;
        if (status_code === 401) {
          message.error("Unauthorized access. Please login again.");
          navigate("/login");
        }
        else if(status_code == 402) {
          alert("License expired. Please contact support.");
        }
        else if(status_code == 403) {
          alert("Reach the maximum token limit in the license. Please contact support.");
        }
        else if(status_code == 404) {
          alert("Invalid License. Please contact support.");
        }
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: USER_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      setIsLoading(false);
      var path_name = `/datachat/session?type=${DataChatServiceType.DOCUMENT_CHAT}&docs=${latestFile}&id=${latestSessionId}`;
      navigate(path_name);
    });
  };
  useEffect(() => {
    StreamText();
  }, []);

  return (
    <div>
      <Header title="header" />
      <div className="body">
        {isLoading && (
          <div className="ss-modal">
            <span class="ss-loader"></span>Analyzing
          </div>
        )}
        <h1 className="instroduce">
          DOCUMENT CHAT
        </h1>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Dragger
            {...props}
            style={{
              width: "350px",
              textAlign: "center",
              backgroundColor: "rgb(255 244 244)",
              padding: "0px",
              border: "2px dashed #666",
              borderRadius: "30px",
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support: PDF, Doc, PPT.</p>
          </Dragger>
        </div>
        <div class="upload-history">
          <div class="upload-history-title">
            <h></h>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionPage;
