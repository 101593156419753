import React, { useRef, useState, useEffect } from "react";
import { Button, Flex, Row, Spin } from "antd";
import "./MainUI.css";
import { UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { message, Upload, Table } from "antd";
import io from "socket.io-client";

import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { solarizedlight } from "react-syntax-highlighter/dist/esm/styles/prism";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import Header from "../header/header";
import { PlusCircleOutlined, SendOutlined } from '@ant-design/icons';

import { useNavigate } from "react-router-dom";
import TokenContext from "../contexts/TokenContext";
import { DataChatServiceType } from "../contexts/Constant";

function MainUI() {
  const user_ctx = React.useContext(TokenContext);
  const user = JSON.parse(localStorage.getItem("user"))
  console.log("user_ctx", user_ctx);
  const host = window.location.host;
  const protocol = window.location.protocol;
  const searchParams = new URLSearchParams(window.location.search);
  const DOCS = searchParams.get('docs');
  const SESSION_ID = searchParams.get('id');
  const DATACHAT_SERVICE_TYPE = searchParams.get('type');

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);

  const [markdownContent, setMarkdownContent] = useState()

  let userId = Cookies.get("userId");
  if (!userId) {
    userId = uuidv4();
    Cookies.set("userId", userId, { expires: 365 });
    console.log("generate New User ID:", userId);
  }

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState("");

  const openImagePopup = (src) => {
    setPopupImageSrc(src);
    setIsPopupOpen(true);
  };

  const closeImagePopup = () => {
    setIsPopupOpen(false);
  };

  const navigate = useNavigate();

  var url = process.env.REACT_APP_API_URL || `${protocol}//${host}`;
  var USER_ID = userId;
  // var SESSION_ID = USER_ID + DOCS;
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [isHighlightVisible, setHighlightVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [latestFile, setLatestFile] = useState(null);
  const conversationEndRef = useRef(null);
  const [doc_src, setSrc] = useState(
    `${url}/pdf.js/web/viewer.html?file=${url}/docs/`
  );
  const [lastPage, setLastPage] = useState(30);
  const [isUploading, setIsUploading] = useState(true);

  useEffect(() => {
    if (DOCS) {
      if (DATACHAT_SERVICE_TYPE == DataChatServiceType.DOCUMENT_CHAT) {
        setSrc(
          `${url}/pdf.js/web/viewer.html?file=${url}/docs/${DOCS}#page=1&zoom=auto&timestamp=${Date.now()}`
        );
      }
      else if (DATACHAT_SERVICE_TYPE == DataChatServiceType.WEB_CHAT) {
        const new_url = `${url}/proxy?url=${DOCS}`;
        setSrc(new_url);
      }
      setLatestFile(DOCS);
      setIsUploading(false);
    }
  }, [DOCS]);

  function clearHighlights() {
    if (lastPage === -1) return;
    const iframeWindow = iframeRef.current.contentWindow;
    if (!iframeWindow) return;
    const iframeDoc = iframeWindow.document;
    const textLayer = iframeDoc.querySelector(
      `#viewer > div:nth-child(${lastPage}) > div.textLayer`
    );
    if (textLayer) {
      const highlight = iframeDoc.querySelector('div[name="last-highlight"]');
      console.log("highlight", highlight);
      if (highlight) {
        textLayer.removeChild(highlight);
      }
    }
  }

  async function checkXFrameOptions(input_url) {
    try {
      const api_url = `${url}/check-url`;
      const response = await fetch(api_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'token': user.token
        },
        body: JSON.stringify({ url: input_url })
      });

      const status = response.status;
      if (status === 200) {
        console.log('X-Frame-Options is not set');
        return true;
      } else if (status === 403) {
        console.log('X-Frame-Options is set');
        alert(`This site is inaccessible due to Content-Security-Policy: ${input_url}`);
      }
      else {
        alert(`This site is inaccessible due to an invalid URL: ${input_url}`);
      }
    } catch (error) {
      console.log('Could not check X-Frame-Options due to CORS restrictions');
    }
    return false;
  }

  async function handleTableClick(subMessage) {
    try {
      const extraInfo = JSON.parse(subMessage.extraInfo);
      const data = extraInfo.data;
      const columns = Object.keys(data[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key: key,
      }));

      const dataSource = data.map((row, index) => ({
        key: index,
        ...row,
      }));
      setDataSource(dataSource);
      setColumns(columns);
    } catch (error) {
      console.error('Failed to handleTableClick', error);
    }
  }

  async function handleMardownClick(subMessage) {
    try {
      const extraInfo = JSON.parse(subMessage.extraInfo);
      const origin_content = extraInfo.origin_content;
      setMarkdownContent(origin_content);
    } catch (error) {
      console.error('Failed to handleMardownClick', error);
    }
  }

  async function handleHyperlinkClick(subMessage) {
    console.log("handleHyperlinkClick", subMessage);
    try {
      const extraInfo = JSON.parse(subMessage.extraInfo);
      const input_url = extraInfo.url;
      if (await checkXFrameOptions(input_url) === false) {
        return;
      }
      setSrc(input_url);
    } catch (error) {
      console.error('Failed to handleHyperlinkClick', error);
    }
  }

  function highlightText(subMessage) {
    console.log("highlightText", subMessage);
    clearHighlights();

    const extraInfo = JSON.parse(subMessage.extraInfo);
    const page = extraInfo.page;
    const coords = extraInfo.coords;
    const pageSize = extraInfo.pageSize;
    const iframeWindow = iframeRef.current.contentWindow;
    if (!iframeWindow) return;
    setSrc(
      `${url}/pdf.js/web/viewer.html?file=${url}/docs/${latestFile ? latestFile : "self_RAG.pdf"
      }#page=${page}&zoom=auto&timestamp=${Date.now()}`
    );
    const iframeDoc = iframeWindow.document;
    if (iframeDoc) {
      const textLayer = iframeDoc.querySelector(
        `#viewer > div:nth-child(${page}) > div.textLayer`
      );
      if (textLayer) {
        console.log("iframe width", textLayer.offsetWidth);
        console.log("iframe height", textLayer.offsetHeight);

        const w_scale = textLayer.offsetWidth / pageSize.width;
        const h_scale = textLayer.offsetHeight / pageSize.height;
        const left = coords[0] * w_scale;
        const top = coords[1] * h_scale;
        const width = coords[2] * w_scale;
        const height = coords[3] * h_scale;

        console.log("left:top-width:height: ", left, top, width, height);

        // clearInterval(intervalId);
        const highlight = document.createElement("div");
        highlight.style.position = "absolute";
        highlight.style.left = left + "px";
        highlight.style.top = top + "px";
        highlight.style.width = width + "px";
        highlight.style.height = height + "px";
        highlight.style.backgroundColor = "yellow";
        highlight.style.opacity = 1;
        highlight.setAttribute("name", "last-highlight");
        textLayer.appendChild(highlight);
        setLastPage(page);

        setTimeout(() => {
          if (iframeDoc) {
            const highlightedContent = iframeDoc.querySelector(
              'div[name="last-highlight"]'
            );
            if (highlightedContent) {
              console.log("highlight", highlightedContent);
              highlightedContent.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "nearest",
              });
            }
          }
        }, 300);

        return true;
      }
    }

    setTimeout(() => {
      highlightText(subMessage);
    }, 100);
    return false;
  }

  const components = {
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <SyntaxHighlighter
          style={solarizedlight}
          language={match[1]}
          PreTag="div"
          children={String(children).replace(/\n$/, "")}
          {...props}
        />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    a({ node, ...props }) {
      return props.href === "#clickable" ? (
        <span
          onClick={() => console.log("Clicked!")}
          style={{ cursor: "pointer", color: "blue" }}
          onMouseOver={(e) => (e.target.style.color = "red")}
          onMouseOut={(e) => (e.target.style.color = "blue")}
        >
          {props.children}
        </span>
      ) : (
        <a {...props}>{props.children}</a>
      );
    },
  };

  const RenderMarkdown = ({ message }) => {
    // Concatenate all sub-messages into a single string
    const allSubMessages = message.subMessages
      .map((subMessage, index) => {
        return subMessage.type === "clickable" || subMessage.type === "image" || subMessage.type === "hyperlink" || subMessage.type === "table" || subMessage.type === "markdown"
          ? `[${subMessage.data}](#clickable-${index})` // Append the index to the href
          : subMessage.data;
      })
      .join(" ");

    const components = {
      code({ node, inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter
            style={solarizedlight}
            language={match[1]}
            PreTag="div"
            children={String(children).replace(/\n$/, "")}
            {...props}
          />
        ) : (
          <code className={className} {...props}>
            {children}
          </code>
        );
      },
      a: ({ node, ...props }) => {
        if (props.href.startsWith("#clickable")) {
          const index = parseInt(props.href.split("-")[1]); // Extract the index from the href
          const subMessage = message.subMessages[index];
          // Check if the subMessage type is an image
          if (subMessage.type === "image") {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={"/" + subMessage.data}
                  alt="Image"
                  style={{
                    width: "50%",
                    height: "auto",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  onClick={() => openImagePopup("/" + subMessage.data)}
                />
              </div>
            );
          }
          else if (subMessage.type === "hyperlink") {
            return (
              <span
                onClick={() => handleHyperlinkClick(subMessage)} // Pass the subMessage to highlightText
                style={{
                  cursor: "pointer",
                  color: "blue",
                  whiteSpace: "pre-wrap",
                  padding: "2px 6px",
                  backgroundColor: "rgb(224, 229, 17)",
                  borderRadius: "24px",
                }}
                onMouseOver={(e) => (e.target.style.color = "red")}
                onMouseOut={(e) => (e.target.style.color = "blue")}
              >
                {props.children}
              </span>
            )
          }
          else if (subMessage.type === "table") {
            return (
              <span
                onClick={() => handleTableClick(subMessage)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  whiteSpace: "pre-wrap",
                  padding: "2px 6px",
                  backgroundColor: "rgb(224, 229, 17)",
                  borderRadius: "24px",
                }}
                onMouseOver={(e) => (e.target.style.color = "red")}
                onMouseOut={(e) => (e.target.style.color = "blue")}
              >
                {props.children}
              </span>
            )
          }
          else if (subMessage.type === "markdown") {
            return (
              <span
                onClick={() => handleMardownClick(subMessage)}
                style={{
                  cursor: "pointer",
                  color: "blue",
                  whiteSpace: "pre-wrap",
                  padding: "2px 6px",
                  backgroundColor: "rgb(224, 229, 17)",
                  borderRadius: "24px",
                }}
                onMouseOver={(e) => (e.target.style.color = "red")}
                onMouseOut={(e) => (e.target.style.color = "blue")}
              >
                {props.children}
              </span>
            )
          }
          else {
            return (
              <span
                onClick={() => highlightText(subMessage)} // Pass the subMessage to highlightText
                style={{
                  cursor: "pointer",
                  color: "blue",
                  whiteSpace: "pre-wrap",
                  padding: "2px 6px",
                  backgroundColor: "rgb(224, 229, 17)",
                  borderRadius: "24px",
                }}
                onMouseOver={(e) => (e.target.style.color = "red")}
                onMouseOut={(e) => (e.target.style.color = "blue")}
              >
                {props.children}
              </span>
            );
          }
        } else {
          return (
            <a {...props} style={{ whiteSpace: "pre-wrap" }}>
              {props.children}
            </a>
          );
        }
      },
    };

    return (
      <div style={{ marginRight: "5px" }}>
        <Markdown
          remarkPlugins={[remarkGfm]}
          className="markdown-table"
          components={components}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {allSubMessages}
        </Markdown>
        {isPopupOpen && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
            onClick={closeImagePopup}
          >
            <img
              src={popupImageSrc}
              alt="Popup"
              style={{ maxWidth: "90%", maxHeight: "90%" }}
            />
          </div>
        )}
      </div>
    );
  };

  const [msg_buffer, setBuffer] = useState({});

  const prevBufferRef = useRef();
  prevBufferRef.current = msg_buffer;

  const socket_tx = useRef();

  useEffect(() => {
    socket_tx.current = io.connect(url);

    return () => {
      if (socket_tx.current) {
        socket_tx.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    conversationEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [msg_buffer]);

  function addMessageV2(message, object_type) {
    // Parse the incoming message
    console.log("pre_msg_buffer", msg_buffer);
    const parsedMessage = JSON.parse(message);

    // Create a new message object
    const newMessage = {
      id: `${parsedMessage.sessionId}-${parsedMessage.chatId}`,
      type: parsedMessage.messageType,
      object: object_type,
      subMessages: parsedMessage.content.map((msg, index) => ({
        index: msg.index,
        type: msg.type,
        data: msg.data,
        extraInfo: msg.extraInfo,
      })),
    };

    // Check if a message with the same ID already exists in msg_buffer
    if (newMessage.id in prevBufferRef.current) {
      if (newMessage.type === "stream") {
        if (prevBufferRef.current[newMessage.id].type === "stream") {
          newMessage.subMessages.forEach((newSubMessage) => {
            const existingSubMessage = prevBufferRef.current[
              newMessage.id
            ].subMessages.find(
              (subMsg) => subMsg.index === newSubMessage.index
            );
            if (existingSubMessage) {
              setBuffer((prevBuffer) => {
                const updatedBuffer = { ...prevBuffer };
                const existingMessage = updatedBuffer[newMessage.id];

                if (existingMessage) {
                  const existingSubMessage = existingMessage.subMessages.find(
                    (subMsg) => subMsg.index === newSubMessage.index
                  );

                  if (existingSubMessage) {
                    existingSubMessage.data += newSubMessage.data;
                  }
                }

                return updatedBuffer;
              });
            } else {
              setBuffer((prevBuffer) => {
                const updatedBuffer = { ...prevBuffer };
                const existingMessage = updatedBuffer[newMessage.id];

                if (existingMessage) {
                  existingMessage.subMessages.push(newSubMessage);
                }

                return updatedBuffer;
              });
            }
          });
        }
      } else {
        setBuffer((prevBuffer) => ({
          ...prevBuffer,
          [newMessage.id]: newMessage,
        }));
      }
    } else {
      setBuffer((prevBuffer) => ({
        ...prevBuffer,
        [newMessage.id]: newMessage,
      }));
    }
    console.log("msg_buffer", msg_buffer);
  }

  var start_message = JSON.stringify({
    sessionId: SESSION_ID,
    messageType: "stream",
    chatId: "1",
    content: [
      {
        index: 0,
        type: "text",
        data: "How can I help you?\n",
        extraInfo: "",
      },
    ],
  });

  useEffect(() => {
    addMessageV2(start_message, "bot");
  }, []);

  const StreamText = () => {
    console.log("socketio: connected to ", url);

    socket_tx.current.on("connect", function () {
      let data = {
        sessionId: SESSION_ID,
      };
      socket_tx.current.emit("register", JSON.stringify(data));
    });

    socket_tx.current.on("message", function (markdownString) {
      console.log("message received: ", markdownString);
      addMessageV2(markdownString, "bot");
      setIsLoading(false);
      setIsUploading(false);
    });
  };

  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  function handleInput(inputValue) {
    const message = JSON.stringify({
      sessionId: SESSION_ID,
      messageType: "full",
      chatId: "user-" + Date.now(),
      content: [
        {
          index: 0,
          type: "text",
          data: inputValue,
          extraInfo: "",
        },
      ],
    });
    addMessageV2(message, "user");

    var api_url = `${url}/api/v1/chat-session/input-prompt`;

    let data = {
      sessionId: SESSION_ID,
      prompt: inputValue,
      chat_service_type: DATACHAT_SERVICE_TYPE
    };
    console.log("chat_prompt", data);
    function postWithRetry(data, attempts = 3) {
      fetch(api_url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'token': user.token
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          console.log("Response:", response.json());
          if (response.status == 401) {
            alert("Session expired. Please login again.");
            navigate("/login");
          }
          else if (response.status == 402) {
            alert("License expired. Please contact support.");
          }
          else if (response.status == 403) {
            alert("Reach the maximum token limit in the license. Please contact support.");
          }
          else if (response.status == 404) {
            alert("Invalid License. Please contact support.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (attempts > 1) {
            console.log(`Retrying... attempts left: ${attempts - 1}`);
            postWithRetry(data, attempts - 1);
          } else {
            console.log("Failed after 3 attempts");
          }
        });
    }
    postWithRetry(data);
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (inputValue === "highlight") {
        highlightText();
      }

      handleInput(inputValue);
      setInputValue("");
      inputRef.current.focus();
    }
  };

  const handleButtonClick = () => {
    console.log("inputValue", inputValue);
    handleInput(inputValue);
    setInputValue("");
    inputRef.current.focus();
  };

  useEffect(() => {
    StreamText();
  }, []);

  useEffect(() => {
    console.log("useEffect msg_buffer", msg_buffer);
    prevBufferRef.current = msg_buffer;
  }, [msg_buffer]);
  const props = {
    name: "file",
    action: `${url}/upload`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        console.log(info.file.response);
        setSrc(
          `${url}/pdf.js/web/viewer.html?file=${url}/docs/${info.file.response.fileName
          }#page=1&zoom=auto&timestamp=${Date.now()}`
        );
        let data = {
          sessionId: SESSION_ID,
          file_name: info.file.response.fileName,
        };
        socket_tx.current.emit("start_new_session", JSON.stringify(data));
        setIsLoading(true);
        setBuffer({});
        setLatestFile(info.file.response.fileName);
        setIsUploading(true);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleNewChat = () => {
    navigate("/");
  };

  return (
    <div className="MainUI2" style={{ display: 'flex', flexDirection: 'column' }}>
      <Header title="header" />
      <div className="MainUI">
        <div className="chat">
          {isLoading && (
            <div className="modal">
              <span class="loader"></span>Loading
            </div>
          )}

          <Row>
            <div className="title2">
              <Button className="new-chat-button" type="primary" icon={<PlusCircleOutlined />} onClick={handleNewChat}>

              </Button>
            </div>
          </Row>
          <Row className="conversationRow">
            <div className="conversation">
              {Object.entries(msg_buffer).map(([messageId, message]) => (
                <div key={messageId} className={message.object}>
                  <div className="message">
                    <div
                      style={{
                        // display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      <RenderMarkdown message={message} />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div ref={conversationEndRef} />
          </Row>
          <Row>
            <div className="input-container">
              <input
                type="text"
                className="message-input"
                placeholder="Type your message..."
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                ref={inputRef}
                disabled={isUploading}
              />
              <Button className="send-button" icon={<SendOutlined />} onClick={handleButtonClick}>

              </Button>
            </div>
          </Row>
        </div>
        <div className="document">
          {DATACHAT_SERVICE_TYPE === DataChatServiceType.CONFLUENCE_CHAT && (
            <Row>
              <div className="title-confluence">
                CONFLUENCE CONTENTS
              </div>
            </Row>
          )
          }

          {DATACHAT_SERVICE_TYPE === DataChatServiceType.DATABASE_CHAT ? (
            <div style={{ display: 'block', justifyContent: 'center', padding: '30px 20px', maxWidth: '100%', maxHeight: '100%', overflow: 'auto' }}>
              <Table
                className="zoom-table"
                dataSource={dataSource}
                columns={columns}
                title={() => 'Database Table'}
                scroll={{ x: 'max-content' }}
                rowClassName={() => 'break-word'}
              />
            </div>
          ) : DATACHAT_SERVICE_TYPE === DataChatServiceType.DOCUMENT_CHAT ? (
            <iframe
              ref={iframeRef}
              className="pdfviewer"
              id="pdfViewer"
              src={doc_src}
            ></iframe>
          ) : DATACHAT_SERVICE_TYPE === DataChatServiceType.WEB_CHAT ? (
            <iframe
              ref={iframeRef}
              className="pdfviewer"
              id="pdfViewer"
              src={doc_src}
            ></iframe>
          ) : (
            <div style={{ display: 'block', justifyContent: 'center', padding: '0px 0px', maxWidth: '100%', maxHeight: '90%', overflow: 'auto' }}>
              <div style={{ display: 'block', justifyContent: 'center', maxWidth: '100%', maxHeight: '100%', overflow: 'auto', paddingLeft: '20px' }}>
                <Markdown
                  remarkPlugins={[remarkGfm]}
                  style={{ whiteSpace: "pre-wrap", overflow: "auto" }}
                >
                  {markdownContent}
                </Markdown>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MainUI;
